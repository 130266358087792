const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_adMrh4y4E",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "1vrquman4sd7v6ad0vk45n09d3",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "beta.datafresh.com.au",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
      // CSRF
      sameSite: "lax"
    }
  },
  ApiUrl: {
    Rest: "https://api.beta.datafresh.com.au",
    WebSocket: "https://api.beta.datafresh.com.au",
    Url: "https://beta.datafresh.com.au"
  },
  Branding: {
    LoginBackground: {
      "localhost.beta.datafresh.com.au": "/media/bg/datafresh-bg.jpg"
    }
  },
  CognitoInformation: {
    IssuerName: "DataFresh-BETA"
  },
  Stripe: {
    clientKey:
      "pk_test_51Mdu53C1WuYzFygfwIgVq7TLmqET1C88f2vvywMPGjFMrAiDEkAfcOBmcKJVcXwo6MeFGBnv2jUe8K7ESaZRPXtM00NLuAVoJf"
  }
};

export default config;
